import { BehaviorSubject } from "rxjs";

export class Modal {
  component = { isOpen: false };
  $modalSubject = new BehaviorSubject({});


  /**
 * Opens a modal with dynamic JSX content provided by the render function.
 * The `render` function receives `handleClose` as a parameter to control modal state.
 * 
 * @param {Function} render - A function that returns JSX content to be displayed inside the modal. 
 *                                   This function will receive `handleClose` as an argument.
 * 
 * @returns {void} This function does not return any value.
 */
  openModal(param) {
    this.$modalSubject.next({
      render:param.render,
      isOpen:true
    });
  }

  close = () => {
    this.$modalSubject.next({ component: this.component, isOpen: false });
  };
}

export const modalService = new Modal();
