// src/App.js

import React from "react";

// New - import the React Router components, and the ApolloClientCreator page component
import { Router, Route, Switch } from "react-router-dom";
import Profile from "./Profile";
import history from "./history";
import PrivateRoute from "./PrivateRoute";
import { ThemeProvider} from "@mui/material";
import { theme } from "./themes/theme";
import ConfirmModal from "./screens/components/ConfirmModal";
import Modal from "./screens/components/Modal";

function App() {
    return (
        <div className="App">
            <ThemeProvider theme={theme}>
            {/* Don't forget to include the history module */}
            <Router history={history}>
                <Switch>
                    <PrivateRoute path="/" component={Profile} />
                </Switch>
            </Router>
            <ConfirmModal/>
            <Modal/>
            </ThemeProvider>
        </div>
    );
}

export default App;
