import { BehaviorSubject } from "rxjs";

export class ConfirmDialog {
  component = { isOpen: false };
  $modalSubject = new BehaviorSubject({});

  openDialog(param) {
    this.$modalSubject.next({
      title: param?.title || "Confirmation",
      message: param?.message || "",
      callback: param?.callback,
      isOpen: true
    });
  }

  close = () => {
    this.$modalSubject.next({ component: this.component, isOpen: false });
  };
}

export const confirmDialog = new ConfirmDialog();
