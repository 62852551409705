import React, { useState, useEffect } from 'react';
import { Dialog,  DialogContent } from '@mui/material';
import { modalService } from '../../lib/modalService';

function Modal() {
    const [currentModal, setCurrentModal] = useState({isOpen:false});
    

    useEffect(() => {
        
        const sub = modalService.$modalSubject.subscribe((value)=>{
            setCurrentModal(value);
        });
        return () => sub.unsubscribe();
      }, []);

      const handleClose=()=>{
        modalService.close()
      }
    
      if (!currentModal.isOpen) return null;

    return (
        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={currentModal.isOpen}
            onClose={handleClose}
        >
            <DialogContent>
                {currentModal.render(handleClose)}
            </DialogContent>
            
        </Dialog>
    );
}

export default Modal;