import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@mui/material';
import { confirmDialog } from '../../lib/dialogService';

function ConfirmModal() {
    const [currentDialog, setCurrentDialog] = useState({isOpen:false});
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    

    useEffect(() => {
        
        const sub = confirmDialog.$modalSubject.subscribe((value)=>{
            setCurrentDialog(value);
            setIsButtonDisabled(false);
        });
        return () => sub.unsubscribe();
      }, []);

      const handleClose=()=>{
        confirmDialog.close()
      }
    
      if (!currentDialog.isOpen) return null;

    return (
        <Dialog
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            open={currentDialog.isOpen}
            onClose={handleClose}
        >
            <DialogTitle id="alert-dialog-title">{currentDialog.title}</DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-description">{currentDialog.message}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                <Button
                    disabled={isButtonDisabled}
                    onClick={async (e) => {
                        e.preventDefault();
                        setIsButtonDisabled(true);
                        currentDialog?.callback && await currentDialog.callback()
                        handleClose();
                    }}
                    color="error"
                    autoFocus
                >
                    Confirm
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default ConfirmModal;
